import {getAllApartments, getApartment, getApartmentByRelation} from "@/api/apartment.api";
import {createApartment, getApartmentReadings, updateApartment} from "../../api/apartment.api";
// import moment from "moment";

// const MONTH_FORMAT = "YYYY-MM";

const state = {
    currentApartment: null,
    apartmentList: [],
    // currentApartmentReadingTotals: {},
    // currentApartmentMonthlyData: {},
    apartmentReadings: {},
}

const getters = {
    currentApartment: (state) => state.currentApartment,
    apartmentList: (state) => state.apartmentList,
    apartmentReadings: (state) => state.apartmentReadings,
}

const actions = {

    async fetchApartmentRelation({commit}, code){
        const response = await getApartmentByRelation(code);
        commit("setCurrentApartment", response.data.apartment);
    },
    async fetchApartment({commit}, id) {
        const response = await getApartment(id);
        commit("setCurrentApartment", response.data.apartment);
    },
    fetchAllApartments: async ({commit}) => {
        const response = await getAllApartments();
        commit("setApartmentList", response.data.apartments);
    },
    updateApartment: async ({commit, state}, data) => {
        const response = await updateApartment(state.currentApartment.id, data);
        commit("setCurrentApartment", response.data.apartment);
    },
    createApartment: async ({commit, getters}, data) => {
        if (data["condominium"] == null && getters.currentCondominium!=null) {
            data["condominium"] = getters.currentCondominium.id;
        }
        const response = await createApartment(data);
        commit("addApartment", response.data.apartment);
        return response.data.apartment;
    },
    async fetchApartmentReadings({commit, state}, query) {
        // if (state.currentApartment.id !== query["id"]) {
        //     await actions.fetchApartment(query["id"]);
        // }
        const response = await getApartmentReadings(state.currentApartment.id, query);
        // console.log(response.data.readings);
        commit("setApartmentReadings", response.data.readings)
    },
    clearReadings: ({commit})=> {
        commit("clearReadings");
    }
}

const mutations = {
    setCurrentApartment: (state, apartment) => state.currentApartment = apartment,
    addMeter: (state, meter) => {
        if (state.currentApartment) {
            state.currentApartment.meters.push(meter);
        }
    },
    setApartmentList: (state, list) => state.apartmentList = list,
    setApartmentReadings: (state, readings) => state.apartmentReadings = readings
}

export default {
    state,
    getters,
    actions,
    mutations
}
