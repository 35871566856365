import { getAllLogs } from "@/api/log.api";

const state = {
    currentLog: null,
    logsList: [],
    totalLogs: 0
}

const getters = {
    currentLog: (state) => state.currentLog,
    logsList: (state) => state.logsList,
    totalLogs: (state) => state.totalLogs,
}

const actions = {
    fetchAllLogs : async({commit}, query) => {
        const response = await getAllLogs(query);
        commit("setLogsList", response.data.logs);
        commit("setTotalLogs", response.data.total);
    }
}

const mutations = {
    setCurrentLog: (state, log) => state.currentLog = log,
    setLogsList: (state, list) => state.logsList = list,
    setTotalLogs: (state, total) => state.totalLogs = total
}

export default {
    state,
    getters,
    actions,
    mutations
}