import httpClient from "@/api/httpClient";

const END_POINT = "/user"

const getUser = (id) => httpClient.get(END_POINT + "/" + id);
const getAllUsers = (query) => httpClient.get(END_POINT + "/", {params: query});
const registerUser = (data) => httpClient.post(END_POINT + "/register", data);
const registerUserBase = (data) => httpClient.post(END_POINT + "/registeration", data);
const activateUser = (data) => httpClient.post(END_POINT + "/activate", data);
const updateUser = (id, data) => httpClient.patch(END_POINT + "/" + id, data);
const updateUserRoles = (id, roles) => httpClient.patch(`${END_POINT}/${id}/roles`, roles);
const updateUserActive = (id, data) => httpClient.patch(`${END_POINT}/${id}/active`, data);
const getAllBasicUsers = () => httpClient.get(END_POINT + "/", { params: { type: 'user' } });
const getAllHomelessUsers = () => httpClient.get(END_POINT + "/", { params: { type: 'user', noHome: true } });
const updatePassword = (id, data) => httpClient.patch(`${END_POINT}/${id}/password`, data);
const resetPassword = (id) => httpClient.post(`${END_POINT}/${id}/password/reset`);
const updateImgData = (id, data) => httpClient.patch(`${END_POINT}/${id}/img`, data);
const deleteUser = (id, data) => httpClient.delete(`${END_POINT}/${id}`, {data: { password:data}});
const getUserByHash = (hash) => httpClient.get(`${END_POINT}/endregistation/${hash}`);
const verifyAuthenticationToken = (id, token) => httpClient.post(`${END_POINT}/verifyToken/${id}`, token)
const getUnactiveUser = () => httpClient.get(`${END_POINT}/unactive`);

export {
    getUser,
    getAllUsers,
    registerUser,
    registerUserBase,
    activateUser,
    updateUser,
    updateUserRoles,
    updateUserActive,
    getAllBasicUsers,
    getAllHomelessUsers,
    updatePassword,
    resetPassword,
    updateImgData,
    deleteUser,
    getUserByHash,
    verifyAuthenticationToken,
    getUnactiveUser
}
