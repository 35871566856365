import {getCondominium} from "@/api/condominium.api";
import {getAllCondominia} from "@/api/condominium.api";
import {createCondominium} from "@/api/condominium.api";
import {
    updateCondominium,
    setActiveCondominium,
    getCondominiumReadings,
    getCondominiumDomesticUsers,
    addCondominiumReadings
} from "@/api/condominium.api";

const state = {
    currentCondominium: null,
    condominiumList: [],
    totalCondominia: 0,
    condominiumReadings: {},
    condominiumDomesticUsers: {}
}

const getters = {
    currentCondominium: (state) => state.currentCondominium,
    condominiumList: (state) => state.condominiumList,
    totalCondominia: (state) => state.totalCondominia,
    condominiumReadings: (state) => state.condominiumReadings,
    condominiumDomesticUsers: (state) => state.condominiumDomesticUsers
}

const actions = {
    async fetchCondominium({commit}, id) {
        const response = await getCondominium(id);
        commit("setCurrentCondominium", response.data.condominium);
    },
    fetchAllCondominia: async ({commit}, query) => {
        const response = await getAllCondominia(query);
        commit("setCondominiumList", response.data.all);
        // commit("setTotalCondominia", response.data.total);
    },
    createCondominium: async ({commit}, data) => {
        const response = await createCondominium(data);
        commit("addCondominium", response.data.condominium);
        return response.data.condominium
    },
    updateCondominium: async ({state}, data) => {
        await updateCondominium(state.currentCondominium.id, data);
        //commit("setCurrentCondominium", response.data.condominium);
    },
    disableCondominium: async ({commit, state}) => {
        const response = await setActiveCondominium(state.currentCondominium.id, false);
        commit("setCurrentCondominium", response.data.condominium);
    },
    activateCondominium: async ({commit, state}) => {
        const response = await setActiveCondominium(state.currentCondominium.id, true);
        commit("setCurrentCondominium", response.data.condominium);
    },
    createCondominiumReadings: async (
        {commit},
        data) => {
        console.log(data);
        const response = await addCondominiumReadings(data.condominium, data.data);
        commit("setCurrentCondominium", response.data.condominium);
        return response.data
    },

    /*
    data {
        id: number -> id condominium
        startDate: string -> DD/MM/YYYY
        endDate: string -> DD/MM/YYYY
    }
     */
    async fetchCondominiumReadings({commit, state}, query) {
        if (state.currentCondominium.id !== query["id"]) {
            await this.fetchCondominium(query["id"]);
        }
        const response = await getCondominiumReadings(state.currentCondominium.id, query);

        commit("setCondominiumReadings", response.data.meterReadings)
    },
    async fetchCondominiumDomesticUsers({commit, state}, query) {
        if (query["id"] && state.currentCondominium.id !== query["id"]) {
            await this.fetchCondominium(query["id"]);
        }
        const response = await getCondominiumDomesticUsers(state.currentCondominium.id, query);

        commit("setCondominiumDomesticUsers", response.data.domesticUsers)
    }
}

const mutations = {
    setCurrentCondominium: (state, condominium) => state.currentCondominium = condominium,
    setCondominiumList: (state, list) => state.condominiumList = list,
    setTotalCondominia: (state, total) => state.totalCondominia = total,
    addApartment: (state, apartment) => {
        if (state.currentCondominium) {
            state.currentCondominium.apartments.push(apartment)
        }
    },
    addCondominium: (state, condominium) => {
        if (state.condominiumList) {
            state.condominiumList.push(condominium)
        }
    },
    setCondominiumReadings: (state, readings) => {
        state.condominiumReadings = readings
    },
    setCondominiumDomesticUsers: (state, dUsers) => {
        state.condominiumDomesticUsers = dUsers
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
