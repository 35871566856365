import httpClient from "@/api/httpClient";

const END_POINT = "/apartment"

const getApartment = (id) => httpClient.get(END_POINT + "/" + id);
const getApartmentByRelation = (code) => httpClient.get(END_POINT + "/relation/" + code);

const getAllApartments = () => httpClient.get(END_POINT + "/");
const getUserApartments = () => httpClient.get(END_POINT + "/user");
const createApartment = (data) => httpClient.post(END_POINT + "/", data)
const getApartmentReadings = (id, query) => httpClient.get(END_POINT + "/" + id + "/readings", { params: query });
const updateApartment = (id, data) => httpClient.patch(END_POINT + "/" + id, data);



export {
    getApartment,
    getAllApartments,
    createApartment,
    getApartmentReadings,
    updateApartment,
    getApartmentByRelation,
    getUserApartments
}
