<template>
  <div v-if="deviceWidth  >= 810">
    <h3>{{ $t('cookieDrawer.title') }}</h3>
    <p> {{ $t("cookieDrawer.text") }}
      <el-link class="el-link" @click="$goTo('cookie-policy')">
        Cookie Policy
      </el-link>
    </p>
    <div class="d-flex">
      <el-switch v-model="cookieNecessari" active-text="Cookies Necessari" style="padding-right: 32px"/>
      <el-switch v-model="cookieMarketing" active-text="Cookies di Marketing" style="padding-right: 32px"/>
      <el-switch v-model="cookieStatistici" active-text="Cookies Statistici" style="padding-right: 32px"/>
      <el-switch v-model="cookiePreferenziali" active-text="Cookies Preferenziali" style="padding-right: 400px"/>
      <el-button type="primary" round @click="setCookie">Salva Preferenze</el-button>
    </div>
  </div>
  <div v-else>
    <p> {{ $t("cookieDrawer.text") }}
      <el-link class="el-link" @click="$goTo('cookie-policy')">
        Cookie Policy
      </el-link>
    </p>
    <div class="d-flex" style="height: 200px">
      <div style="padding-bottom: 18px;width: 1000px" >
        <el-switch v-model="cookieNecessari" active-text="Necessari" style="padding-right: 32px"/>
      </div>
      <div style="padding-bottom: 18px;width: 1000px">
      <el-switch v-model="cookieMarketing" active-text="Marketing" style="padding-right: 32px"/>
      </div>
      <div style="width: 1000px">
        <el-switch v-model="cookieStatistici" active-text="Statistici" style="padding-right: 32px"/>
      </div>
      <div style="padding-bottom: 24px; width: 1000px;padding-top: 18px">
        <el-switch v-model="cookiePreferenziali" active-text="Preferenziali" style="padding-right: 400px"/>
      </div>
      <div style="display: flex;justify-content: center;align-items: center;">
        <el-button type="primary" round @click="setCookie">Salva Preferenze</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {useCookies} from "vue3-cookies";
import {ref} from "vue";

let cookieNecessari = ref(true);
let cookieMarketing = ref(false);
let cookieStatistici = ref(false);
let cookiePreferenziali = ref(false);

export default {
  name: "CookieManager",
  emits: ["accept", "deny"],
  data() {
    const acceptedCookieName = 'Sunny_policy_accepted';
    const preferenceCookieName = 'Sunny_preference_accepted';
    const statisticCookieName = 'Sunny_statistic_accepted';
    const marketingCookieName = 'Sunny_marketing_accepted';

    const {cookies} = useCookies();
    if (cookies.get(acceptedCookieName) === null &&
        cookies.get(marketingCookieName) === null &&
        cookies.get(preferenceCookieName) === null &&
        cookies.get(statisticCookieName) === null) {
      this.open();
    }

    return {
      cookieNecessari,
      cookieMarketing,
      cookieStatistici,
      cookiePreferenziali,
      acceptedCookieName,
      preferenceCookieName,
      statisticCookieName,
      marketingCookieName,
      deviceWidth: window.innerWidth

    }
  },
  methods: {
    close() {
      this.$emit('close', true);
    },
    open() {
      this.$emit('open', true);
    },
    checkCookies() {
      const {cookies} = useCookies();
      if (!cookies.get(this.acceptedCookieName) || cookies.get(this.acceptedCookieName) === null) {
        console.log("Sono dentro l'if di check");
        this.acceptedCookieName = true;
      }
      if (cookies.get(this.marketingCookieName) === null ||
          cookies.get(this.preferenceCookieName) === null ||
          cookies.get(this.statisticCookieName) === null) {
        this.cookieMarketing = false;
        this.cookieStatistici = false;
        this.cookiePreferenziali = false;
        this.open();
      }
    },
    setCookie() {
      const {cookies} = useCookies();
      cookies.set(this.acceptedCookieName, 'true', "1y");
      if (!this.cookieMarketing) {
        cookies.set(this.marketingCookieName, 'false', "1y");
      } else {
        cookies.set(this.marketingCookieName, 'true', "1y");
      }
      if (!this.cookiePreferenziali) {
        cookies.set(this.preferenceCookieName, 'false', "1y");
      } else {
        cookies.set(this.preferenceCookieName, 'true', "1y");
      }
      if (!this.cookieStatistici) {
        cookies.set(this.statisticCookieName, 'false', "1y");
      } else {
        cookies.set(this.statisticCookieName, 'true', "1y");
      }
      this.close();
      window.setInterval(this.checkCookies, 2500);
    }
  }
}
</script>
