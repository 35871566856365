import { createApp } from "vue";
import ElementPlus from 'element-plus';
import it from 'element-plus/es/locale/lang/it'
import './assets/styles/theme.scss';
import App from './App.vue';
import store from './store';
import router from "./router/router";
import i18n from "./i18n";
import VueCookies from "vue3-cookies";
import { createPinia } from 'pinia';
import mitt from 'mitt';

const emitter = mitt();
const app = createApp(App);
const pinia = createPinia();
app.config.globalProperties.$goTo = (name) => {
    router.push({name})
}
app.use(store);
app.use(router);
app.use(ElementPlus,{
    locale: it,
  });
app.use(i18n);
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    httponly:false,
    secure: false,
    sameSite: "None"
});
app.use(pinia);
app.config.globalProperties.emitter = emitter;
app.mount('#app');

const globalProperties = app.config.globalProperties;

export default globalProperties;
