import httpClient from "@/api/httpClient";

const END_POINT = "/meter"

const getMeter = (id) => httpClient.get(END_POINT + "/" + id);
const getMeterBySerialNumber = (serial) => httpClient.get(END_POINT + "/serial/" + serial);
const getAllMeters = () => httpClient.get(END_POINT + "/");
const createMeter = (data) => httpClient.post(END_POINT + "/", data);
const updateMeter = (id, data) => httpClient.patch(END_POINT + "/" + id, data);
const replaceMeterById = (oldId, data) => httpClient.post(END_POINT + "/" + oldId + "/replace_by_id", data);
const replaceMeterBySerial = (oldSerial, data) => httpClient.post(END_POINT + "/" + oldSerial + "/replace_by_serial", data);
const addReadingById = (id, data) => httpClient.post(END_POINT + "/id/" + id + "/reading", data);
const addReadingBySerial = (serial, data) => httpClient.post(END_POINT + "/serial/" + serial +"/reading", data);
const getConsumptionByDate = (serial, date) => httpClient.get(END_POINT + "/serial/" + serial +"/consumption?date="+date);
const getConsumptionEstimation = (serial, startDate, endDate) => httpClient.get(END_POINT + "/serial/" + serial +"/consumption?estimation=true&&startDate="+startDate+"&&endDate="+endDate);
const getMissingReadingMeters = (serial, date) => httpClient.get(END_POINT + "/serial/" + serial +"/readings/missing?date="+date);

export {
    getMeter,
    getMeterBySerialNumber,
    getAllMeters,
    createMeter,
    updateMeter,
    replaceMeterById,
    replaceMeterBySerial,
    addReadingById,
    addReadingBySerial,
    getConsumptionByDate,
    getConsumptionEstimation,
    getMissingReadingMeters
}
