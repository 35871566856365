<template>
  <div v-if="deviceWidth  >= 810">
  <el-drawer
      :with-header="false"
      v-model="show"
      v-if="!cookiePage"
      direction="btt"
      :before-close="handleClose"
      :close-on-click-modal="false"
  >
    <CookieManager @close="handleClose" @open="handleOpen"></CookieManager>
  </el-drawer>
  </div>
  <div v-else>
    <el-dialog
        v-model="show"
        width="50%"
        :title="$t('cookieDrawer.title')"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <CookieManager @close="handleClose" @open="handleOpen"></CookieManager>
    </el-dialog>
  </div>
</template>

<script>
import CookieManager from "./CookieManager";

export default {
  name: "CookieDrawer",
  components: {CookieManager},
  data(){
    return{
      show: true,
      cookiePage: false,
      deviceWidth: window.innerWidth
    }
  },

  methods: {

    handleClose(){
      this.show = false;
    },
    handleOpen(){
      this.show = true;
    },
  },
  watch: {
    async $route() {
      this.cookiePage = this.$route.path === "/cookie-policy";
    }
  },
}
</script>

<style scoped>

</style>