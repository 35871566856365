<template>
  <el-container v-loading.fullscreen.lock="isLoading">
    <router-view></router-view>
    <cookie-drawer></cookie-drawer>
  </el-container>
</template>

<script>

import {mapGetters} from "vuex";
import CookieDrawer from "./components/CookieDrawer";

export default {
  name: 'App',
  components: {CookieDrawer},
  computed: {
    ...mapGetters(["isLoading"]),
  },
  beforeCreate() {
    this.$store.dispatch("loadPageUser");
    this.$store.dispatch("loadPageCondominium");
  }
}
</script>

<style>


</style>
