import { createI18n } from "vue-i18n";
import it from "./locales/it.json";


const messages = {
  "it": {...it}
}


export default createI18n({
    locale: "it",
    fallbackLocale: "it",
    legacy: false,
    globalInjection: true,

    messages: messages
  });
