import axios from "axios";
import store from "../store";
import router from "../router/router";
import globalProperties from '../main';

const httpClient = axios.create({
    baseURL:  process.env.VUE_APP_SERVER_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

// interceptor to catch errors
const errorInterceptor = async error => {
    await store.dispatch("stopLoading");

    // check if it's a server error
    if (!error.response) {
        return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
        case 400:
            globalProperties.$message({message: error.response.data.message, type: "error"});
            await store.dispatch("stopForceLoading");
            break;
        case 401: // authentication error, logout the user
            if (error.response.data.message==="first_access"){
                // await store.commit("setFirstAccess", true);
                if (router.currentRoute.name !== 'activation'){
                    router.push({name: 'activation'})
                }
                await store.dispatch("stopForceLoading");
                break;
            }
            globalProperties.$message({message: error.response.data.message, type: "error"});
            await store.dispatch("stopForceLoading");
            await router.push('/dashboard');
            break;
        case 403:
            await store.dispatch("logout");
            globalProperties.$message({message: "Non hai i permessi necessari per fare quest'operazione", type: "error"});
            break;
        default:
            globalProperties.$message({message: error.response.data.detail, type: "error", showClose: true, duration: 0});
            await store.dispatch("stopForceLoading");
            console.error("status", error.response.status);
            console.error("message", error.message);
            console.error("data", error.response.data);
    }
    return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = async response => {
    switch (response.status) {
        case 200:
            // succ!
            break;
        case 201:
            // Creata entita
            break;
        default:
        // default case
    }
    await store.dispatch("stopLoading");
    return response;
}

const requestInterceptor = async (config) => {
    await store.dispatch("startLoading");
    let token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = "Bearer " + token
    }
    return config
}

httpClient.interceptors.request.use(requestInterceptor)
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
