import { getMeter } from "@/api/meter.api";
import {getAllMeters} from "@/api/meter.api";
import {
    createMeter,
    getConsumptionByDate,
    getMeterBySerialNumber,
    replaceMeterById,
    replaceMeterBySerial,
    updateMeter,
    getConsumptionEstimation,
    getMissingReadingMeters
} from "../../api/meter.api";

const state = {
    currentMeter: null,
    meterList: [],
    meterConsumption: null,
    meterConsumptionEstimation: null,
    missingReadingMeters: null
}

const getters = {
    currentMeter: (state) => state.currentMeter,
    meterList: (state) => state.meterList,
    meterConsumption: (state) => state.meterConsumption,
    meterConsumptionEstimation: (state) => state.meterConsumptionEstimation,
    missingReadingMeters: (state) => state.missingReadingMeters,
}

const actions = {
    async fetchMeter({commit}, id){
        const response = await getMeter(id);
        commit("setCurrentMeter", response.data.meter);
    },
    async fetchMeterBySerial({commit}, serial){
        const response = await getMeterBySerialNumber(serial);
        if(response.data.meter){
            commit("setCurrentMeter", {...response.data.meter, isOwner: response.data.isOwner, found: response.data.found});
        }
    },
    fetchAllMeters: async ({commit}) => {
        const response = await getAllMeters();
        commit("setMeterList", response.data.meters);
    },
    createMeter: async ({commit, getters}, data) => {
        if (data["apartment"]==null){
            data["apartment"] = getters.currentApartment.id;
        }
        const response = await createMeter(data);
        commit("addMeter", response.data.meter);
        return response.data.meter;
    },
    updateMeter: async ({commit}, {id, data}) => {
        const response = await updateMeter(id, data);
        commit("setCurrentMeter", response.data.meter);
    },
    replaceMeterById: async ({state, commit}, data) => {
        const response = await replaceMeterById(state.currentMeter.id, data);
        commit("setCurrentMeter", response.data.meter);
    },
    replaceMeterBySerial: async ({commit}, data) => {
        const response = await replaceMeterBySerial(data.oldMeter, data);
        commit("setCurrentMeter", response.data.meter);
    },
    fetchMeterConsumption: async ({commit}, data) => {
        const response = await getConsumptionByDate(data['serial'], data['date']);
        commit("setMeterConsumption", response.data.consumption);
    },
    fetchMeterConsumptionEstimation: async ({commit}, data) => {
        const response = await getConsumptionEstimation(data['serialNumber'], data['startDate'], data['endDate']);
        commit("setMeterConsumptionEstimation", response.data.consumption);
    },
    fetchMissingReadingMeters: async ({commit}, data) => {
        const response = await getMissingReadingMeters(data['serialNumber'], data['date']);
        commit("setMissingReadingMeters", response.data.meterList);
    }
    //getMissingReadingMeters
}

const mutations = {
    setCurrentMeter: (state, meter) => state.currentMeter = meter,
    setMeterList: (state, list) => state.meterList = list,
    addMeter: (state, meter) => {
        if (state.meterList) {
            state.meterList.push(meter)
        }
    },
    setMeterConsumption: (state, consumption) => state.meterConsumption = consumption,
    setMeterConsumptionEstimation: (state, consumption) => state.meterConsumptionEstimation = consumption,
    setMissingReadingMeters: (state, list) => state.missingReadingMeters = list
}

export default {
    state,
    getters,
    actions,
    mutations
}
