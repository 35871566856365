import {
    getAllUsers,
    getUser,
    registerUser,
    registerUserBase,
    updateUser,
    updateUserActive,
    updateUserRoles,
    getAllBasicUsers,
    updateImgData, 
    getAllHomelessUsers,
    deleteUser,
    getUnactiveUser,
    getUserByHash
} from "../../api/user.api";

const state = {
    currentUser: null,
    userList: [],
    totalUsers: 0,
    basicUserList: [],
    homelessUserList: [],
    condominiumAdministratorList: [],
    unactiveUserList : []
}

const getters = {
    currentUser: (state) => state.currentUser,
    userList: (state) => state.userList,
    homelessUserList: (state) => state.homelessUserList,
    totalUsers: (state) => state.totalUsers,
    basicUserList: (state) => state.basicUserList,
    condominiumAdministratorList: (state) => state.condominiumAdministratorList,
    unactiveUserList: (state) => state.unactiveUserList
}

const actions = {
    fetchUnactiveUsers : async ({commit}) => {
        const response = await getUnactiveUser();
        commit("setUnactiveUsers", Object.values(response.data));
    },
    async fetchUser({commit}, id) {
        const response = await getUser(id);
        commit("setCurrentUser", response.data.user);
    },
    async fetchUserByHash({commit}, hash) {
        const response = await getUserByHash(hash);
        commit("setCurrentUser", response.data.user);
    },
    fetchAllUsers: async ({commit}, query) => {
        const response = await getAllUsers(query);
        commit("setUserList", response.data.users);
        commit("setTotalUsers", response.data.total);
    },
    registerUser: async ({commit}, data) => {
        const response = await registerUser(data);
        commit("addUser", response.data.user);
        return response.data.user;
    },
    registerUserBase: async ({commit}, data) => {
        const response = await registerUserBase(data);
        commit("addUser", response.data.user);
        return response.data.user;
    },
    updateUser: async ({state, commit}, data) => {
        const response = await updateUser(state.currentUser.id, data);
        commit("setCurrentUser", response.data.user);
    },
    updateUserRoles: async ({state, commit}, data) => {
        const response = await updateUserRoles(state.currentUser.id, data);
        commit("setCurrentUser", response.data.user);
    },
    updateUserActive: async ({state}) => {
        await updateUserActive(state.currentUser.id, {active: state.currentUser.active})
    },
    fetchAllBasicUsers: async ({commit}) => {
        const response = await getAllBasicUsers();
        commit("setBasicUserList", response.data.users);
    },
    fetchAllHomelessUsers: async ({commit}) => {
        const response = await getAllHomelessUsers();
        commit("setHomelessUserList", response.data.users);
    },
    fetchAllCondominiumAdministrators: async ({commit}, query) => {
        let condoAdminQuery = {...query};
        condoAdminQuery.type = 'admin';
        const response = await getAllUsers(condoAdminQuery);
        commit("setCondominiumAdministratorList", response.data.users);
    },
    updateImgData: async ({state, commit}, data) => {
        //sending converted data (base64)
        const response = await updateImgData(state.currentUser.id, data);
        commit("setCurrentUser", response.data.user);
    },
    deleteUser: async({commit}, data) => {
        const response = await deleteUser(data.id,data.password);
        commit("deleteUser",response.data.user);
    }
}

const mutations = {
    setCurrentUser: (state, user) => state.currentUser = user,
    setUserList: (state, list) => state.userList = list,
    setTotalUsers: (state, value) => state.totalUsers = value,
    setBasicUserList: (state, list) => state.basicUserList = list,
    setHomelessUserList: (state, list) => state.homelessUserList = list,
    setCondominiumAdministratorList: (state, list) => state.condominiumAdministratorList = list,
    addUser: (state, user) => {
        if (state.userList) {
            state.userList.push(user)
        }
    },
    deleteUser: (state, user) => {
        if(state.userList){
            state.userList = state.userList.filter(itemToRemove => itemToRemove !== user)
        }
        
    },
    setUnactiveUsers: (state, users) => state.unactiveUserList = users,
}

export default {
    state,
    getters,
    actions,
    mutations
}
