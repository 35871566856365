import {login} from "../../api/app.api";
import {getAllCondominia, getCondominium} from "@/api/condominium.api";
import {getUserApartments} from "@/api/apartment.api";

import jwt_decode from "jwt-decode";

const permissions = {
    "ROLE_USER": 0,
    "ROLE_CONDO": 1,
    "ROLE_MANAGER": 2,
    "ROLE_ADMIN": 3
}


const state = {
    sidebarIsCollapse: false,
    loading: false,
    forceLoading: false,
    user: null,
    condominium: null,
    condominiumList: null,
    firstAccess: false,
    tmpUserData: {},
}

const getters = {
    sidebarIsCollapse: (state) => state.sidebarIsCollapse,
    isLoading: state => state.loading,
    isLogged: state => state.user!==null,
    isFirstAccess: state => state.firstAccess,
    userFullname: state => state.user.surname + " " + state.user.name,
    userRoles: state => state.user ? state.user.roles : [],
    appUser: state => state.user,
    appCondominium: state => state.condominium,
    appCondominiumList: state => state.condominiumList,
    isGranted: state => role => {
        if(!state.user){
            return false;
        }
        const target = permissions[role];
        for(let r of state.user.roles){
            if(permissions[r] >= target){
                return true;
            }
        }
    },
    getUserActivationData: state=> state.tmpUserData,
}

const actions = {
    extendSidebar: ({commit}) => commit("setSidebarIsCollapse", false),
    collapseSidebar: ({commit}) => commit("setSidebarIsCollapse", true),
    toggleSidebar: ({commit, state}) => commit("setSidebarIsCollapse", !state.sidebarIsCollapse),
    startLoading: ({commit}) => commit("setLoading", true),
    stopLoading: ({commit, state}) => commit("setLoading", state.forceLoading),
    stopForceLoading: ({commit}) => {
        commit("setLoading", false);
        commit("setForceLoading", false);
    },
    startForceLoading: ({commit}) => {
        commit("setLoading", true);
        commit("setForceLoading", true);
    },
    login: async ({commit, getters, state, dispatch}, data) => {
        let response = await login(data);
        const user = jwt_decode(response.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", response.data.token);
        commit("setUser", user)

        // let condoResponse = await getAllCondominia()      
        // const condoList = condoResponse.data.condominia
        // commit("setCondominia", condoList)

        await dispatch("fetchAppCondominiumList")
        const condoList = state.condominiumList
        

        let condo = null;
        if (getters.isGranted("ROLE_MANAGER")){
            condo = condoList["all"][0]
        } else if (user.type==='admin'){
            if (condoList["owner"].length>0){
                condo = condoList["owner"][0]
            } else if (condoList["admin"].length>0){
                condo = condoList["admin"][0]
            } else if (condoList["technician"].length>0){
                condo = condoList["technician"][0]
            } else if (condoList["supplier"].length>0){
                condo = condoList["supplier"][0]
            }
        } else {
            console.log(condoList);
            console.log(condoList["apartments"]);
            
            if (condoList["apartments"][0]){
                condo = condoList["apartments"][0].condominium
            }
            
            //todo select automatically the first apartment
        }
        commit("setAppCondominium", condo)
        localStorage.setItem("condominium", JSON.stringify(condo));
        
    },
    logout: ({commit}) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("condominium");
        commit("setUser", null);
    },
    loadPageUser: ({commit}) => {
        let user = localStorage.getItem("user");
        if(user && user!==""){
            commit("setUser", JSON.parse(user))
        }
    },
    loadPageCondominium: ({commit}) => {
        let condominium = localStorage.getItem("condominium");
        if(condominium && condominium!==""){
            commit("setAppCondominium", JSON.parse(condominium))
        }
    },
    setUserActivationData: ({commit}, data) => {
        commit("setTmpUserData", data);
    },
    fetchAppCondominiumList: async ({commit}) => {
        let condoResponse = await getAllCondominia()      
        let condoList = condoResponse.data
        let apartmentResponse = await getUserApartments()

        const apartmentList = apartmentResponse.data.apartments
        condoList["apartments"] = apartmentList

        commit("setCondominia", condoList)
    },
    fetchAppCondominium: async ({commit}, id) => {
        let condoResponse = await getCondominium(id)      
        const condo = condoResponse.data.condominium
        commit("setAppCondominium", condo)
        localStorage.setItem("condominium", JSON.stringify(condo));
    },

}

const mutations = {
    setSidebarIsCollapse: (state, value) => state.sidebarIsCollapse = value,
    setLoading: (state, value) => state.loading = value,
    setUser: (state, user) => state.user = user,
    setForceLoading: (state, value) => state.forceLoading = value,
    setFirstAccess: (state, value) => state.firstAccess = value,
    setTmpUserData: (state, userData) => state.tmpUserData = userData,
    setCondominia: (state, condominia) => state.condominiumList = condominia,
    setAppCondominium: (state, condominium) => state.condominium = condominium
}

export default {
    state,
    getters,
    actions,
    mutations
}
