import {createRouter, createWebHashHistory} from "vue-router";
import store from "../store";


const Dashboard = () => import("@/views/Dashboard");
const Login = () => import("@/views/Login");
const Registration = () => import("@/views/Registration");
const Association = () => import("@/views/Association");

const Logout = () => import("@/views/Logout");
const Activation = () => import("@/views/Activation");
const CookiePolicy = () => import("@/views/CookiePolicy");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy");
const ContactUs = () => import("@/views/ContactUs");

//dashboard
const DashboardIndex = () => import("@/views/Dashboard/Index");
const DashboardUserList = () => import("@/views/Dashboard/UserList");
const DashboardCondominiumList = () => import("@/views/Dashboard/CondominiumList");
const DashboardUser = () => import("@/views/Dashboard/User");
const DashboardCondominium = () => import("@/views/Dashboard/Condominium");
const DashboardDataImport = () => import("@/views/Dashboard/DataImport");
const DashboardCondominiumImport = () => import("@/views/Dashboard/CondominiumImport");
const DashboardApartment = () => import("@/views/Dashboard/Apartment");
const DashboardReadings = () => import("@/views/Dashboard/Readings");
// const DashboardApartments = () => import("@/views/Dashboard/Apartments");
const ActivateUser = () => import("@/views/ActivateUser");
const VerifyTokenUser = () => import("@/views/VerifyToken");
const DashboardLogs = () => import("@/views/Dashboard/LogsList");

const dashboardChildren = [
    {
        path: '',
        name: "dashboardIndex",
        component: DashboardIndex,
    },
    {
        path: 'utenti',
        name: 'utenti',
        component: DashboardUserList,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: 'condomini',
        name: 'condomini',
        component: DashboardCondominiumList,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: 'condominio/:id',
        name: 'condominio',
        component: DashboardCondominium,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: 'utente',
        name: 'utente corrente',
        component: DashboardUser,
    },
    {
        path: 'utente/:id',
        name: 'utente',
        component: DashboardUser,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: 'dataimport',
        name: 'dataimport',
        component: DashboardDataImport,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: 'condominiumimport',
        name: 'condominiumimport',
        component: DashboardCondominiumImport,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: "/association",
        name: "association",
        component: Association
    },
    {
        path: 'appartamento/:id',
        name: 'appartamento',
        component: DashboardApartment,
        meta: {
            requiresAdmin: false
        }
    },
    {
        path: 'letture/:id',
        name: 'letture',
        component: DashboardReadings,
        meta: {
            requiresAdmin: true
        }
    },
    // {
    //     path: 'appartamenti/',
    //     name: 'appartamenti',
    //     component: DashboardApartments,
    // },
    {
        path: 'logs',
        name: 'logs',
        component: DashboardLogs,
        meta: {
            requiresAdmin: true
        }
    }
]

const routes = [
    {
        path: '/',
        redirect: {
            name: "dashboard"
        }
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/registration",
        name: "registration",
        component: Registration
    },

    {
        path: "/activation",
        name: "activation",
        component: Activation
    },
    {
        path: '/activate-user/:hash',
        name: 'activateUser',
        meta: {requiresAuth: false},
        component: ActivateUser,
    },
    {
        path: '/verifyToken/:id',
        name: 'VerifyToken',
        meta: {requiresAuth: false},
        component: VerifyTokenUser,
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout
    },
    {
        path: "/cookie-policy",
        name: "cookie-policy",
        component: CookiePolicy
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy
    },
    {
        path: "/contact-us",
        name: "contact-us",
        component: ContactUs
    },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {requiresAuth: true},
        component: Dashboard,
        children: dashboardChildren
    },
    {
        path: "/**",
        redirect: {
            name: "dashboard"
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('token');
    let isAdmin = false
    if (loggedIn) {
        const roles = store.getters.userRoles
        isAdmin = roles && roles.findIndex(e => e === "ROLE_MANAGER" || e === "ROLE_ADMIN");
    }
    const requiresAuth = to.meta["requiresAuth"];
    const requiresAdmin = to.meta["requiresAdmin"];

    if (requiresAuth && !loggedIn) {
        next('/login');
    } else if (requiresAdmin && !isAdmin) {
        next('/dashboard');
    } else if (to.path === "/login" && loggedIn) {
        next('/dashboard');
    } else {
        next();
    }
});
export default router
